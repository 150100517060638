html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	position: fixed;
}

#root {
	width: 100%;
	max-height: 100%;
	height: 100%;
}

#menu-appbar ul {
	padding: 0;
}

div[portal-container] {
	position: relative;
	z-index: 1500;
}

input,
textarea {
	font-family: 'Inter';
	border: none;
}
